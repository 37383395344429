<template>
  <div class="home">
    <h1>Du hast dich erfolgreich angemeldet!</h1>
    <p>Du hast Fragen oder Anregungen? Schreibe mir direkt eine E-Mail an <a
        href="mailto:sbrinkmann@54gradsoftware.de">sbrinkmann@54gradsoftware.de</a>.
    </p>
  </div>
</template>
<style scoped lang="scss">
.home {
  width: 100%;
  margin-top: 8rem;
  text-align: center;
}
</style>